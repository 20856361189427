import React from "react"
import logoStyles from "../styles/logo.module.css"

const IndexPage = () => {
  return (
    <div className={logoStyles.logo}>
      <h>nexVim</h>
    </div>
  )
}

export default IndexPage
